<template>
  <div>
    <PageTitle v-bind:title="'DocLing Search'" />

    <b-container fluid="xxl" class="mt-2" v-if="context">
      <b-row class="mt-4">
        <b-col cols="1">
          <b-form-group label="Search by" class="small-title">
            <b-form-radio-group
              v-model="searchByType"
              :options="searchByOptions"
              name="radios-btn-default"
              size="sm"
              buttons
            ></b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col>
          <h6>{{ $t('DocSearchQuery') }}</h6>
          <b-form-input
            placeholder="Query (RegEx)"
            v-model="queryRegex"
            size="sm"
          ></b-form-input>
        </b-col>
        <b-col v-if="config.editable">
          <h6>Options</h6>
          <b-form-select
            size="sm"
            v-model="lemmatizedOptionSelected"
            :options="lemmatizedOptions"
            :disabled="searchByType != 'token'"
          ></b-form-select>
        </b-col>
        <b-col cols="6">
          <h6>{{ $t('DocSearchCorpora') }}</h6>
          <Select2
            :options="corpusesList"
            v-model="selectedCorpora"
            :settings="{ multiple: true }"
            v-if="config.editable"
          />
          <CorpusSelectPI
            v-else
            class="mb-3"
            @select="selectCorpus"
          />
        </b-col>
        <b-col v-if="config.editable">
          <h6>Language</h6>
          <Select2
            :options="languagesOptions"
            v-model="languageSelected"
            :settings="{ multiple: true }"
          />
        </b-col>
        <b-col>
          <b-button
            :disabled="queryRegex.length == 0"
            @click="searchTypes"
            size="sm"
            class="mt-custom-form"
          >
            {{ $t('DocSearchSearch') }}
            <b-icon icon="search" aria-hidden="true"></b-icon>
          </b-button>
        </b-col>
      </b-row>
      <hr>
      <b-row class="mt-4" v-if="sortedTypes.length !== 0">
        <b-col cols="2">
          <b-row>
            <div cols="12 affix">
              <h6>Search Result
                ({{ sortedTypes.length }} <span v-if="sortedTypes.length === 1">{{ searchByType }}</span><span v-else>{{ searchByType == 'token' ? 'types' : 'lemmas' }}</span>)
              </h6>
              <b-button size="sm" class="mr-2" @click="loadContextForSelected">
                {{ $t('DocSearchLoad') }}
              </b-button>
              <b-button size="sm" variant="primary" @click="selectAll" class="mr-1">
                {{ $t('DocSearchSelect') }}
              </b-button>
              <b-button size="sm" variant="primary" @click="deselectAll" class="">
                {{ $t('DocSearchDeselect') }}
              </b-button>
            </div>
            <b-col cols="12" class="scroll-vertical mt-3 pl-0">
              <b-row class="ml-1 mt-0">
                <b-form-group>
                  <b-form-checkbox-group stacked>
                    <b-form-checkbox
                      v-for="token in sortedTypes"
                      :key="token.form"
                      :value="token"
                      v-model="selectedForms"
                    >
                      <span v-html="tokenDisplay(token)"></span>
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </b-form-group>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
          <!-- </b-row>
        </b-col> -->
        <b-col cols="10" class="scroll-vertical" v-if="selectedForms.length">
          <!-- <DMSOccurrences :context="context" :key="occurrencesIndex" @update="updateContext" /> -->
          <DMSOccurrencesTable :context="context" :key="occurrencesIndex" @update="updateContext" :type="searchByType" />
        </b-col>
      </b-row>
      <b-row class="mt-4" v-else>
        <b-col>No results <span v-if="submitedSearchQuery">for <b>{{ submitedSearchQuery }}</b></span></b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style scoped>
* >>> .small-title legend {
  font-size: 10px !important;
  text-transform: uppercase;
  color: #bbbbbb;
}
* >>> .select2 .selection {
  line-height: 1.12 !important;
}

* >>> .select2-container--default .select2-selection--multiple {
  border: 1px solid #ced4d9;
}

.mt-custom-form {
  margin-top: 20px;
}

.scroll-vertical {
  height: 100vh;
  overflow-y: auto;
}

h6 {
  margin-bottom: calc(0.375rem + 5px);
  margin-top: 1px;
}
</style>

<script>
import { mapState } from "vuex";
import PageTitle from "@/components/PageTitle.vue";
import Select2 from "v-select2-component";
import config from '@/config.js'
import CorpusSelectPI from '@/components/Document/CorpusSelectPI.vue'
// import DMSOccurrences from "@/views/doc/DMSOccurrences.vue"
import DMSOccurrencesTable from "@/views/doc/DMSOccurrencesTable.vue"

export default {
  name: "DocumentManagementSystem",
  components: {
    PageTitle,
    Select2,
    // DMSOccurrences,
    DMSOccurrencesTable,
    CorpusSelectPI,
  },

  data() {
    this.$store.state.lemma.lemmas = [];

    return {
      config: config,
      queryRegex: "",
      // window: 200,
      occurrences: [],
      selectedForms: [],

      occurrencesIndex: 0,

      lemmatizedOptionSelected: "all",
      lemmatizedOptions: ["all", "lemmatized", "not lemmatized"],

      languageSelected: [],

      searchByType: "token",
      searchByOptions: [
        { text: this.$t('DocSearchType'), value: 'token' },
        { text: this.$t('DocSearchLemma'), value: 'lemma' },
      ],
      selectedCorpora: [],
      submitedSearchQuery: '',
    };
  },

  mounted() {
    this.$store.dispatch("loadAllCorpuses")
    this.$store.state.docediting.types = []
    this.$store.state.docediting.selectedTokensLemmas = []

    if (!config.editable) {
      this.$store.dispatch("loadPIData");
    }
  },

  methods: {
    searchTypes() {
      this.selectedForms = []
      this.$store.dispatch("searchTypes", {
        queryRegex: this.queryRegex.toLowerCase(),
        searchBy: this.searchByType,
        // corpuses: this.selectedCorpuses.length ? this.selectedCorpuses : this.corpusesList.map(corpus => corpus.id),
        corpora: this.selectedCorpora.length ? this.selectedCorpora : [],
        // languages: this.languageSelected.length ? this.languageSelected : this.languagesOptions.map(language => language.id)
        languages: this.languageSelected.length ? this.languageSelected : [],
        lemmatized: this.lemmatizedOptionSelected
      })
      this.$store.state.docediting.tokensSettings = null
      this.$store.state.docediting.context = []
      this.submitedSearchQuery = this.queryRegex
      this.updateContext()
    },
    selectCorpus(corpora) {
      let selectedIds = corpora.map(corpus => corpus.id)
      this.selectedCorpora = this.corpuses.filter(corpus => selectedIds.includes(corpus.id)).map(corpus => corpus.identifier)
    },
    tokenDisplay(token) {
      let count = 0
      for (let list of Object.values(token.document_id_token_ids)){
        count += list.length
      }
      return `${token.form} (${count})`
    },
    updateContext() {
      // let documentIds = new Set()
      // let tokensIds = new Set()
      this.$store.state.docediting.context = []
      this.$store.state.docediting.selectedTokensLemmas = []

      if (this.selectedForms.length) {
        // console.log("B0", this.selectedForms)
        // for (let token of this.selectedForms){
        //   for (let documentId in token.document_id_token_ids){
        //     documentIds.add(documentId)
        //     // token.document_id_token_ids[documentId].forEach(tokensIds.add, tokensIds)
        //     tokensIds.add([token.document_id_token_ids[documentId]])
        //   }
        // }
        // console.log("B1", tokensIds, documentIds)
        this.$store.dispatch("loadContext", this.selectedForms);
        // this.$store.dispatch("loadContext", {
        //   tokens: [...tokensIds],
        //   documents: [...documentIds],
        // });
      }
    },
    loadContextForSelected() {
      this.updateContext()
    },
    selectAll() {
      this.selectedForms = this.sortedTypes
    },
    deselectAll() {
      this.selectedForms = []
    }
  },

  computed: {
    ...mapState({
      languagesOptions: (state) => {
        return state.common.languages.map((language) => {
          return {
            id: language.code,
            text: `${language.name} (${language.code})`,
          };
        });
      },
      corpusesList: (state) => {
        return state.corpus.corpuses.filter(corpora => corpora.edition != null).map((corpora) => {
          return {
            id: corpora.edition.sigleLegam,
            text: corpora.edition.sigleLegam,
          };
        });
      },
      corpuses: (state) => state.corpus.corpuses,
      sortedTypes: (state) => {
        return state.docediting.types;
      },
      context: (state) => state.docediting.context,
    }),
  },

  watch: {
    // selectedForms() {
    //   this.updateContext()
    // },
    context() {
      this.occurrencesIndex++
    },
    searchByType() {
      this.searchTypes()
    }
  }
};
</script>
